const siteWideBannerFix = () =>{

    const $body = $("body.has-globalBanner");
    const $header = $("header");


    const adjustHeight = () => {

        if ($body.length && window.innerWidth < 801) {
            $body.css("padding-top", $header.height())
        }
        else
            {
                $body.removeAttr("style");
            }
        }

    $(window).on("DOMContentLoaded load resize",adjustHeight);

}

export default siteWideBannerFix;
