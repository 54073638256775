import $ from 'jquery';
import 'foundation-sites/js/foundation/foundation';
import 'foundation-sites/js/foundation/foundation.dropdown';
import utils from '@bigcommerce/stencil-utils';
import swal from "sweetalert2";
import ShippingEstimator from "../cart/shipping-estimator";
import {breakpointSizes} from "../common/media-query-list";
import {STORAGE_KEY} from "../opt7/couponCodeCheck";

export const CartPreviewEvents = {
    close: 'closed.fndtn.dropdown',
    open: 'opened.fndtn.dropdown',
};

export default function (secureBaseUrl,page_type) {
    const loadingClass = 'is-loading';
    const $cart = $('[data-cart-preview]');
    const $cartDropdown = $('#cart-preview-dropdown');
    const $cartLoading = $('<div class="previewCart"><div class="loadingOverlay"></div></div>');

    const $body = $('body');

    $body.on('cart-quantity-update', (event, quantity) => {
        $('.cart-quantity')
            .text(quantity)
            .addClass('countPill--positive');
    });

    $(document).on("click", function(ev) {
        if ($(ev.target).closest('.navUser-item--cart, #cart-preview-dropdown, .cart-icon, [data-prevent-quick-search-close], [data-cart-edit-item-remove],.swal2-container ').length === 0) {
            $('body').removeClass('side-cart-open');
        }
    });

    $(document).on('click', '.previewCart-icon-close', (event) => {
        event.preventDefault();
        $body.removeClass('side-cart-open');
    });

    let preVal;

    const cartUpdate=($target)=>{

        const itemId = $target.data('cartItemid');
        const $el = $(`#qty-${itemId}`);
        const oldQty = parseInt($el.val(), 10);
        const maxQty = parseInt($el.data('quantityMax'), 10);
        const minQty = parseInt($el.data('quantityMin'), 10);
        const minError = $el.data('quantityMinError');
        const maxError = $el.data('quantityMaxError');
        const newQty = $target.data('action') === 'inc' ? oldQty + 1 : oldQty - 1;
        // Does not quality for min/max quantity
        if (newQty < minQty) {
            return swal({
                text: minError,
                type: 'error',
            });
        } else if (maxQty > 0 && newQty > maxQty) {
            return swal({
                text: maxError,
                type: 'error',
            });
        }


        utils.api.cart.itemUpdate(itemId, newQty, (err, response) => {

            if (response.data.status === 'succeed') {
                // if the quantity is changed "1" from "0", we have to remove the row.
                const coupon_code = localStorage.getItem(STORAGE_KEY);
                if(coupon_code){
                    utils.api.cart.applyCode(coupon_code,(error,response)=>{
                        localStorage.removeItem(`${STORAGE_KEY}_ERROR`)

                        if(response && response.data && response.data.errors){
                            localStorage.setItem(`${STORAGE_KEY}_ERROR`,response.data.errors.join("</br>"));
                        }

                        if(error) {
                            console.log(error.data);
                        }
                        refreshContent();
                    })
                }else {
                    refreshContent();
                }




            } else {
                $el.val(oldQty);
                swal({
                    text: response.data.errors.join('\n'),
                    type: 'error',
                });
            }
        });
    }

    const cartUpdateQtyTextChange=($target, preVal = null)=> {
        const itemId = $target.data('cartItemid');


        const $el = $(`#qty-${itemId}`);
        const maxQty = parseInt($el.data('quantityMax'), 10);
        const minQty = parseInt($el.data('quantityMin'), 10);
        const oldQty = preVal !== null ? preVal : minQty;
        const minError = $el.data('quantityMinError');
        const maxError = $el.data('quantityMaxError');
        const newQty = parseInt(Number($el.attr('value')), 10);
        let invalidEntry;
        // Does not quality for min/max quantity
        if (!newQty) {
            invalidEntry = $el.attr('value');
            $el.val(oldQty);
            return swal({
                text: `${invalidEntry} is not a valid entry`,
                type: 'error',
            });
        } else if (newQty < minQty) {
            $el.val(oldQty);
            return swal({
                text: minError,
                type: 'error',
            });
        } else if (maxQty > 0 && newQty > maxQty) {
            $el.val(oldQty);
            return swal({
                text: maxError,
                type: 'error',
            });
        }

        utils.api.cart.itemUpdate(itemId, newQty, (err, response) => {
            this.$overlay.hide();

            if (response.data.status === 'succeed') {
                // if the quantity is changed "1" from "0", we have to remove the row.
                const remove = (newQty === 0);

                this.refreshContent(remove);
            } else {
                $el.val(oldQty);
                swal({
                    text: response.data.errors.join('\n'),
                    type: 'error',
                });
            }
        });
    }

    const cartRemoveItem=(itemId) =>{

        utils.api.cart.itemRemove(itemId, (err, response) => {
            if (response.data.status === 'succeed') {
                refreshContent();
            } else {
                swal({
                    text: response.data.errors.join('\n'),
                    type: 'error',
                });
            }
        });
    }

    const bindPromoCodeEvents = () => {
        const $couponContainer = $('.coupon-code');
        const $couponForm = $('.coupon-form.preview');
        const $codeInput = $('[name="couponcode"]', $couponForm);

        $('.coupon-code-add').on('click', event => {
            event.preventDefault();

            $(event.currentTarget).hide();
            $couponContainer.show();
            $('.coupon-code-cancel').show();
            $codeInput.trigger('focus');
        });

        $('.coupon-code-cancel').on('click', event => {
            event.preventDefault();

            $couponContainer.hide();
            $('.coupon-code-cancel').hide();
            $('.coupon-code-add').show();
        });

        $couponForm.on('submit', event => {
            const code = $codeInput.val();

            event.preventDefault();

            // Empty code
            if (!code) {
                return swal({
                    text: $codeInput.data('error'),
                    type: 'error',
                });
            }

            utils.api.cart.applyCode(code, (err, response) => {
                if (response.data.status === 'success') {
                    refreshContent();
                } else {
                    swal({
                        text: response.data.errors.join('\n'),
                        type: 'error',
                    });
                }
            });
        });
    }

    const registerEvents = () => {
        $('.previewCart [data-cart-update]').on('click', (event) => {
            const $target = $(event.currentTarget);

            event.preventDefault();

            try {
                // update cart quantity

                cartUpdate($target)
            }catch (e){
                swal({
                    text: e.join('\n'),
                    type: 'error',
                });
            }

        });
        // cart qty manually updates
        $('.previewCart .cart-item-qty-input').on('focus', () => {
            preVal = this.value;
        }).change(event => {
            const $target = $(event.currentTarget);
            event.preventDefault();


            // update cart quantity
            cartUpdateQtyTextChange($target, preVal);
        });

        $('.btn-removeItem').on('click', event => {
            event.preventDefault();

            const itemId = $(event.currentTarget).data('cart-itemid');
            const string = $(event.currentTarget).data('confirm-delete');

            swal({
                text: string,
                type: 'warning',
                showCancelButton: true,
            }).then(() => {
                // remove item from cart
                cartRemoveItem(itemId);
            });
            event.preventDefault();
        });


        // indicate content is scrollable and set height to two row

        const $list = $(".previewCartList");
        const rows = window.innerWidth > breakpointSizes.medium ? 2 : 1;

        if($list){
            let firstTwoHeight = 0;

            $list.children()
                .each(function (index,elem) {
                    if(index<rows){
                        firstTwoHeight += elem.clientHeight;
                    }else{
                        return false
                    }
                });


            if($list.prop("scrollHeight") > $list.prop("clientHeight")){
                $(".previewCart-header").addClass('shadow');
                $(".previewCart-bottom").addClass('shadow');
                $list.css("max-height",rows===2?firstTwoHeight:firstTwoHeight*1.3);
            }
        }

        let shippingEstimator = new ShippingEstimator($('[data-shipping-estimator]'));

        bindPromoCodeEvents();
    }// register events

    const  refreshContent = ()=> {

        const options = {
            template: 'common/cart-preview',
        };

        $cartDropdown
            .addClass(loadingClass)
            .html($cartLoading);

        $cartLoading
            .show()
            .find(".loadingOverlay")
            .show();

        utils.api.cart.getContent(options, (err, response) => {
            const response_node = $(response)
            const statusMessage = response_node.find(".alertBox-message span");

            const coupon_code = localStorage.getItem(STORAGE_KEY);
            const coupon_code_error = localStorage.getItem(`${STORAGE_KEY}_ERROR`);

            const alertBox = response_node.find(".alertBox-coupon")

            if(coupon_code && coupon_code_error) {
                alertBox.html(`<p>Coupon Code: ${coupon_code}</p><p>${coupon_code_error}</p>`)
            }else {
                alertBox.remove();
            }

            if(page_type==="cart"){
                response_node.find(".cart-additionalCheckoutButtons").remove()
            }

            $cartDropdown
                .removeClass(loadingClass)
                .html("")
                .append(response_node);

            $cartLoading
                .hide();
            const msg = statusMessage.text()

            $('.previewCartItem').each((index,elem)=>{
                const name = $(elem).data("name");

                if(msg.includes(name)){
                    const alert = response_node.find(".alertBox--info");
                    $('.previewCart .alertBox').hide();

                    $(elem).append(alert)
                    alert.show();

                }
            })

            const quantity = $('[data-cart-quantity]').data('cartQuantity') || 0;


            $('body').trigger('cart-quantity-update', quantity);

            registerEvents();
        });


    }





    $cart.on('click', event => {

        // Redirect to full cart page
        //
        // https://developer.mozilla.org/en-US/docs/Browser_detection_using_the_user_agent
        // In summary, we recommend looking for the string 'Mobi' anywhere in the User Agent to detect a mobile device.

        event.preventDefault();

        $('body').toggleClass('side-cart-open');

        refreshContent();

    });

    let quantity = 0;

    // Get existing quantity from localStorage if found
    if (utils.tools.storage.localStorageAvailable()) {
        if (localStorage.getItem('cart-quantity')) {
            quantity = Number(localStorage.getItem('cart-quantity'));
            $body.trigger('cart-quantity-update', quantity);
        }
    }

    // Get updated cart quantity from the Cart API
    const cartQtyPromise = new Promise((resolve, reject) => {
        utils.api.cart.getCartQuantity({ baseUrl: secureBaseUrl }, (err, qty) => {
            if (err) {
                reject(err);
            }
            resolve(qty);
        });
    });

    // If the Cart API gives us a different quantity number, update it
    cartQtyPromise.then(qty => {
        $body.trigger('cart-quantity-update', qty);
        if (utils.tools.storage.localStorageAvailable()) {
            localStorage.setItem('cart-quantity', qty);
        }
    });

}
