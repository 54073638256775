const yotpoLink = () => {

    const targetNode = document.getElementById('yotpo-carousel');
    const klevuNode = document.getElementById('klevu_landing');

// Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

// Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
            if (mutation.type === 'childList') {

                $(".single-carousel")
                    .css("cursor","pointer")
                    .on("click",(event)=>{

                        const $target = $(event.currentTarget);
                        const link = $target.find(".product-link-container").prop("href");
                        window.location.href=link;
                    })
                observer.disconnect();
            }
        }
    };

// Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

// Start observing the target node for configured mutations
    observer.observe(targetNode, config);
    let isFixed = false;

    if(klevuNode) {
        const callback_search =  (mutationList, observer) => {
            for (const mutation of mutationList) {
                    const loaded = mutation.target.classList.contains("kuSearchResultsPageContainer")
                    if(loaded){
                        const selector =$(".klevuProduct .yotpo.bottomLine");
                        if(selector.length>0){
                            selector.on("click",(event)=>{
                                const url = $(event.currentTarget).data("url");
                                window.location.href = url ;
                            })
                        }
                        if(!isFixed){
                            const badges = $(".ku_promotionBadge span");
                            badges.each((index,elem)=>{
                                const text = elem.innerText;
                                $(elem)
                                    .attr("data-promotion",text)
                            })
                            isFixed=true;
                        }

                        observer.disconnect();
                    }
                }
            }
            const observer_search = new MutationObserver(callback_search);

            observer_search.observe(klevuNode, config);
        }

}

export default yotpoLink;
