import {api} from "@bigcommerce/stencil-utils";


!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();

const klaviyoAnalytics = (context) => {

    if(!context.customer) return false;

    if(!context.customer.email) return false;

    const email = context.customer.email;

    //console.log(email);

    api.getPage('/account.php?action=account_details',{
        template: 'opt7/forms-json'
    },  (err, response) => {
        if (err) {
            return false;
        }
        if(response) {
            const data = JSON.parse(response);

            if(data.length>0){
                const fields = data.find((field) => field.label.includes('I am best described as:'));
                if(fields){
                    const type = fields.radios.find((radio) => radio.checked);
                    if(type){
                        const customerType = type.label;
                        const identifyCallback = (response) => {
                            console.log("response", response);
                        };

                        const identify = {
                            '$email': email,
                            'Customer Type': customerType
                        };
                        console.log(identify);

                        if(klaviyo && klaviyo.identify){
                            klaviyo.identify(identify, identifyCallback);
                        }
                    }
                }
            }
        }
    });

};

export default klaviyoAnalytics;
