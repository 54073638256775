import getUrlVars from "./utils/getUrlVars";
import utils from "@bigcommerce/stencil-utils";

/**
 *
 * Check Url Has Coupon Code Param and Apply to The Cart
 * @param cart_id {?string}
 */

export const STORAGE_KEY = "GROOMERS_COUPON_CODE";
const couponCodeCheck = (coupons,cart_id) =>{
    let stored_code = null;

    try {
        stored_code = localStorage.getItem(STORAGE_KEY);
    } catch (error) {
        console.log("Error When Writing to Local Storage",error);
        //rg4js('send', {error});
    }

    const params = getUrlVars();
    if(params && params.coupon_code && params.coupon_code.length>0){
        console.log("Stored Code:", stored_code);

        //Stored Code is Different From New Code Replace Stored Code with New One

        if(params.coupon_code !== stored_code) {
            localStorage.setItem(STORAGE_KEY, params.coupon_code);
        }
    }



    if(cart_id && params && params.coupon_code ){
        console.log("Cart is Present", cart_id);
        utils.api.cart.applyCode(params.coupon_code,(error,response)=>{
            localStorage.removeItem(`${STORAGE_KEY}_ERROR`)

            //if success open preview
            if(response && response.data.status==="success"){
                $("[data-cart-preview]").trigger("click");
            }

            if(response && response.data && response.data.errors){
                localStorage.setItem(`${STORAGE_KEY}_ERROR`,response.data.errors.join("</br>"));
            }

            if(error) {
                console.log(error.data);
            }
        })
    }


}

export default couponCodeCheck;
