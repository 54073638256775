import $ from 'jquery';
import './common/select-option-plugin';
import PageManager from './page-manager';
import quickSearch from './global/quick-search';
import currencySelector from './global/currency-selector';
import mobileMenuToggle from './global/mobile-menu-toggle';
import menu from './global/menu';
import foundation from './global/foundation';
import quickView from './global/quick-view';
import cartPreview from './global/cart-preview';
import compareProducts from './global/compare-products';
import privacyCookieNotification from './global/cookieNotification';
import maintenanceMode from './global/maintenanceMode';
import carousel from './common/carousel';
import loadingProgressBar from './global/loading-progress-bar';
import sweetAlert from './global/sweet-alert';
import svgInjector from './global/svg-injector';
import newsletterPopup from './f/newsletter-popup';
import imageHover from './f/image-hover';
import AOS from 'aos';
// You will need a ResizeObserver polyfill for browsers that don't support it! (iOS Safari, Edge, ...)
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

import softAddToCart from "./global/addToCart";
import siteWideBannerFix from "./opt7/SitewideBannerFix";
import yotpoLink from "./opt7/yotpoLink";

import couponCodeCheck from "./opt7/couponCodeCheck";
import klaviyoAnalytics from "./opt7/klaviyo-analytics";

export default class Global extends PageManager {
    onReady() {
        // Only load visible elements until the onload event fires,
        // after which preload nearby elements


        cartPreview(this.context.secureBaseUrl,this.context.page_type);
        quickSearch();
        currencySelector();
        foundation($(document));
        quickView(this.context);
        compareProducts(this.context.urls);
        carousel();
        menu();
        mobileMenuToggle();
        privacyCookieNotification();
        maintenanceMode(this.context.maintenanceMode);
        loadingProgressBar();
        sweetAlert();

        /* Optimum7 */
        klaviyoAnalytics(this.context);
        couponCodeCheck(this.context.coupons, this.context.cart_id);
        siteWideBannerFix();
        softAddToCart();
        if($(".yotpo-reviews-carousel").length) yotpoLink();
        /* Optimum7 */

        if (this.context.enableNewsletterPopup) {
            newsletterPopup(this.context.newsletterPopupDelay, this.context.reappearAfter, this.context.pageType);
        }
        if (this.context.enableImageHoverSwitcher) {
            imageHover();
        }
        if (this.context.enableAnimations) {
            AOS.init();
        }
        svgInjector();

        $(document).scroll(() => {
            if ($(window).scrollTop() === 0) {
                $(document).foundation('topbar', 'reflow');
            }
        });

        $(document).foundation('equalizer', 'reflow');

        //bundle b2b
        const url = 'https://cdn.bundleb2b.net/b3-auto-loader.js';
        const el = document.createElement('script');
        el.setAttribute('src', url);
        document.querySelector('body').append(el);
        window.b3themeConfig = window.b3themeConfig || {}
        window.b3themeConfig.keepSuperAdminMasquerade = true;
        window.b3themeConfig.useJavaScript = {
           login: {
              callback() {
                 document.querySelector('.body').style.display = "block";
              }
           }
        }
        window.b3themeConfig.useContainers = {
            'dashboard.endMasquerade.container': '#menu',
        }
    }
}
