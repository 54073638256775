import $ from 'jquery';
import utils from '@bigcommerce/stencil-utils';
import Swal from './sweet-alert';

const softAddToCart=()=> {
    const cartProcess = {
        const: {
            $body: $('body'),
            $cart : $('[data-cart-preview]'),
            $addToCartButton: '.btnATC',
            quantity: '1',
            cartArgs: {
                action: null, fastcart: null, product_id: null, qty: null,
            },
        },
        func: {
            getCart: (qty, cartItemHash) => {

                window.scrollTo({top:0,behavior:"smooth"});
                cartProcess.const.$cart.trigger('click');


                /*
                Get These from sidecart
                const options = {
                    template: 'cart/preview',
                    params: {
                        suggest: cartItemHash,
                    },
                };
                utils.api.cart.getContent(options, (err, response) => {
                    if (err) {
                        return;
                    }
                    cartProcess.const.modal.updateContent(response);
                    // eslint-disable-next-line no-shadow
                    const $cartQuantity = $('[data-cart-quantity]', cartProcess.const.modal.$content);
                    const $cartCounter = $('.navUser-action .cart-count');
                    const cartQuantity = $cartQuantity.data('cart-quantity') || 0;
                    $cartCounter.addClass('cart-count--positive');
                    cartProcess.const.$body.trigger('cart-quantity-update', cartQuantity);
                });
                */
            },
            addToCart: (data, addToCartButton, ATCButtonText) => {
                const formData = new FormData();



                // eslint-disable-next-line guard-for-in,no-restricted-syntax
                for (const key in data) {
                    formData.append(key, data[key]);
                }

                utils.api.cart.itemAdd(formData, (err, response) => {
                    const errorMessage = err || response.data.error;



                    if (errorMessage) {
                        const tmp = document.createElement('DIV');
                        tmp.innerHTML = errorMessage;
                        return Swal.fire({
                            text: tmp.textContent || tmp.innerText,
                            icon: 'error',
                        });
                    }

                    addToCartButton.html(ATCButtonText);

                    const prod =  response.data.line_items.find(x=>x.product_id===data.product_id);

                    if(prod){
                        dataLayer.push({
                            event: "add_to_cart",
                            ecommerce: {
                                items: [
                                    {
                                        item_id: prod.sku || prod.product_id,
                                        item_name: prod.product_name,
                                        currency: prod.currency,
                                        index: 0,
                                        item_brand: prod.brand_name || "",
                                        item_category: prod.category_names[0] || "",
                                        item_category2: prod.category_names[1] || "",
                                        item_category3: prod.category_names[2] || "",
                                        item_category4: prod.category_names[3] || "",
                                        item_category5:prod.category_names[4] || "",
                                        price: prod.base_price,
                                        quantity: parseInt(data.qty),
                                    }
                                ]
                            }
                        })
                    }


                    // eslint-disable-next-line max-len
                    cartProcess.func.getCart(cartProcess.const.quantity, response.data.cart_item.hash);

                    return true;
                });
            },
        },
    };

    cartProcess.const.$body.on('click', cartProcess.const.$addToCartButton, (event) => {
        const $target = $(event.currentTarget);

        const ATCButtonText = $target.html();

        //console.log("Add to Cart Triggered");

        $(event.currentTarget).text('Adding to cart...');

        event.preventDefault();

        const productIdValue = $(event.currentTarget).data('productId');

        cartProcess.const.cartArgs.action = 'add';
        cartProcess.const.cartArgs.fastcart = '1';
        cartProcess.const.cartArgs.product_id = productIdValue;
        cartProcess.const.cartArgs.qty = cartProcess.const.quantity;


        cartProcess.func.addToCart(cartProcess.const.cartArgs, $target, ATCButtonText);
    });
}
// eslint-disable-next-line func-names
export default softAddToCart
